import React from 'react';
import {
  AppBar, Toolbar, Typography, Button, TextField, Container, Grid, Paper,
  Box, useTheme, CircularProgress, Backdrop
}
  from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import { DataGrid } from "@mui/x-data-grid";
import { useState } from 'react';
import { FaRegCheckCircle } from "react-icons/fa";
import { IoIosHourglass } from "react-icons/io";
import { tokens } from "./theme";
import InputMask from 'react-input-mask';

const App = () => {
  const [pageSize, setPageSize] = useState(12);
  const [valores, setValores] = useState([]);
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const [idUsuaria, setIdusuaria] = useState('');
  const apiHost = process.env.REACT_APP_API_URL_FIN || "http://localhost:8015";

  const handleSearch = () => {

    if (idUsuaria) {
      setLoading(true);
      if (idUsuaria) {
        fetch(`${apiHost}/site/usuaria?numeroOns=${idUsuaria}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              'Access-Control-Allow-Origin': '*'
            }
          })
          .then(response => response.json())
          .then(data => {
            setData(data);
            const sortedData = data
              .map(item => ({ ...item, id: item.numeroAvc }))
              .sort((b, a) => {
                const [monthA, yearA] = a.periodoContabilInt.split('-').map(Number);
                const [monthB, yearB] = b.periodoContabilInt.split('-').map(Number);
                if (yearA !== yearB) {
                  return yearA - yearB;
                }
                return monthA - monthB;
              });
            setValores(sortedData);
          })
          .catch(error => {
            setLoading(false);
            console.error('Erro ao buscar ID Usuária:', error);
          })
          .finally(() => {
            setLoading(false);
          })
      } else {
        setLoading(false);
        alert('Por favor, preencha o ID Usuária.');
      }
    }

  };

  const handleClear = () => {
    setData([]);
    setValores([]); // Limpa os dados da DataGrid
    setIdusuaria(''); // Limpa o valor da caixa de texto
  };


  const columns = [
    { field: "idUsuaria", headerName: "Codigo Ons" },
    {
      field: "transmissoras",
      headerName: "Usuária",
      headerAlign: 'center',
      flex: 2,
      cellClassName: "name-column--cell",
    },
    {
      field: "competencia",
      headerName: "Competência",
      flex: 1.5,
      headerAlign: 'center',
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Typography >
          {params.row.periodoContabil}
        </Typography>
      ),
    },
    {
      field: "cnpj",
      headerName: "CNPJ",
      flex: 1.5,
      headerAlign: 'center',
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Typography >
          {params.row.cnpj ? params.row.cnpj.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
            '$1.$2.$3/$4-$5'): ''}
        </Typography>
      ),
    },
    {
      field: "valorParcela1",
      headerName: "Xml",
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => (
        <Typography style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }} >
          {params.row.linkXml ?
            <a href={params.row.linkXml} target="_blank" rel="noopener noreferrer">
              <FaRegCheckCircle color={colors.greenAccent[500]} /></a>
            : <IoIosHourglass color="red" />}
        </Typography>
      ),
    },
    {
      field: "valorParcela2",
      headerName: "Danfe",
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Typography style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }} >
          {params.row.linkDanfe ?
            <a href={params.row.linkDanfe} target="_blank" rel="noopener noreferrer">
              <FaRegCheckCircle color={colors.greenAccent[500]} /></a>

            : <IoIosHourglass color="red" />}
        </Typography>
      ),
    },
    {
      field: "valorParcela3",
      headerName: "Boleto",
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => (
        <Typography style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }} >
          {params.row.linkBoleto ?
            <a href={params.row.linkBoleto} target="_blank" rel="noopener noreferrer">
              <FaRegCheckCircle color={colors.greenAccent[500]} /> </a>
            : <IoIosHourglass color="red" />}
        </Typography>
      ),
    },
    {
      field: "totalComPisPasep",
      headerName: "Valor Avc",
      flex: 1,
      renderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          {params.row.totalOns !== null ? params.row.totalOns.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : (0.0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
      ),
    }
  ];


  return (
    <div>
      {/* Cabeçalho */}
      <AppBar position="static" style={{ backgroundColor: '#008c9e' }}>
        <head>
          <title>Tropicalia Transmissora</title>
          <link rel="icon" href="../../assets/logo_trop.png" />
        </head>
        <Toolbar>
          <img
            src={`../../assets/logo_trop.png`}
            alt="Tropicalia Logo" style={{ height: 50, marginRight: 20 }} />
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            TROPICÁLIA - Transmissora de Energia S.A.
          </Typography>
          <Button color="inherit"
            href="https://tropicaliatransmissora.com.br/"
            target="_blank">Home</Button>
          <Button color="inherit" href="https://nf-tropicalia-transmissora.cust.app.br/"
            target="_blank">Notas Fiscais</Button>
          <Button color="inherit" href="http://webmail.tropicaliatransmissora.com.br/"
            target="_blank">Webmail</Button>
        </Toolbar>
      </AppBar>
      <Container>
        <Paper style={{ padding: '20px', marginTop: '20px' }}>
          <Typography variant="h5" style={{ marginBottom: '20px' }}>
            Pesquisar nota/boleto
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <InputMask
                mask="9999"
                value={idUsuaria}
                onChange={(e) => setIdusuaria(e.target.value)}
              >
                {(inputProps) => <TextField {...inputProps} label="Codigo ONS" fullWidth />}
              </InputMask>
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" color="primary" onClick={handleSearch} startIcon={<SearchIcon />}>
                Pesquisa
              </Button>
            </Grid>
          </Grid>
          <Button variant="outlined" color="secondary" onClick={handleClear} style={{ marginTop: '20px' }} startIcon={<ClearIcon />}>
            Limpar
          </Button>
        </Paper>
      </Container>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        <Box
          m="10px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .no-border-bottom": {
              borderBottom: "none !important",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.blueAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          {data.length === 0 ? "" : (
            <DataGrid
              rows={valores}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[12, 24, 36]}
              style={{ margin: '0 auto' }}
              getRowId={(row) => row.periodoContabil}
              pagination

            />
          )}

        </Box>
      </Container>

      {/* Rodapé */}
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
          backgroundColor: '#008c9e',
          color: 'white',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <img src={`../../assets/logo_trop.png`} alt="Tropicalia Logo" style={{ height: 50 }} />
              <Typography variant="body2">
                TROPICÁLIA - Transmissora de Energia S.A.
              </Typography>
              <Button color="inherit"
                href="https://exitconsulting.com.br/"
                target="_blank">© 2024 Desenvolvido por Exit Consulting</Button>
            </Grid>
            <Grid item xs={12} sm={4}>

              <Typography variant="body2">Matriz: Rua Prof. Álvaro Rodrigues, 352 - 7º andar - Botafogo - Rio de Janeiro - RJ, CEP: 22780-040</Typography>
              <Typography variant="body2">Filial: Av Governador Lomanto Junior, 1237 - Joaquim Romão, Jequié, Bahia, CEP 45.200-601</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2">Atendimento à Comunidade: 0800-777-1215</Typography>
              <Typography variant="body2">contato@tropicaliatransmissora.com.br</Typography>
              <Typography variant="body2">Tel: 21 3262-6000</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default App;
